import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ProfessionalEdit from "./ProfessionalEdit";
import RemoveList from "../../../components/RemoveList/RemoveList";
import { ProfessionalActionTypes } from "../../../store/professionals/actionTypes";
import { userStatus } from "../../../helpers/userStatus";
import QRCodeView from "../../../components/QRCode/QRCodeView";
import ServiceList from "../../../components/ServiceListUpdate/ServiceList";
import { connect } from "react-redux";
import { getTranslation } from "../../../store/translations/actions";
import Ratings from "components/Ratings/Ratings";
import WorkSchedule from "../../../components/WorkShedule/WorkSchedule";
import { createProfessionalMetaHeadData } from "../../../store/metaHead/actions";
import dayjs from "dayjs";

class ProfessionalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      isRemoveModalOpen: false,
      isServiceEditModalOpen: false,
      isWorkScheduleModalOpen: false,
      isIdCardOpen: false,
      idCardProfessional: null,
      removeProfessional: null,
      editProfessional: null,
      isViewRatingsOpen: null,
      professionalListData: [],
      defaultValues: {
        metadata: { services: [{ serviceName: "", price: 0, duration: 20 }] },
      },
      title: "",
      subTitle: "",
      status: "",
    };
  }

  componentDidMount() {
    this.props.getTranslation({ type: "normal" });
  }

  onEditOpen = (professional) => {
    this.setState((prevState) => ({
      isEditModalOpen: !prevState.isEditModalOpen,
      editProfessional: professional,
    }));

    if(this.state.isEditModalOpen) {
      window.location.reload()
    }
  };

  onServiceOpen = (professional) => {
    let defaultServices = {
      metadata: { services: [{ serviceName: "", price: 0, duration: 20 }] },
    };
    if (
      professional &&
      professional?.metadata &&
      professional?.metadata.services.length
    ) {
      defaultServices = {
        metadata: { services: professional?.metadata.services },
      };
    }
    this.setState({
      isServiceEditModalOpen: !this.state.isServiceEditModalOpen,
      editProfessional: professional,
      defaultValues: defaultServices,
    });
  };

  onRatingsOpen = (professional) => {
    this.setState({
      isViewRatingsOpen: !this.state.isViewRatingsOpen,
      editProfessional: professional,
    });
  };
  onWorkScheduleOpen = (professionalId) => {
    this.setState({
      isWorkScheduleModalOpen: !this.state.isWorkScheduleModalOpen,
      editProfessional: professionalId,
    });
  };
  onIdCardOpen = (professional) => {
    this.setState({
      isIdCardOpen: !this.state.isIdCardOpen,
      editProfessional: professional,
      idCardProfessional: professional?.metadata?.idCard,
    });
  };
  onRemoveOpen = (professional, title, subTitle, status) => {
    this.setState((prevState) => ({
      isRemoveModalOpen: !prevState.isRemoveModalOpen,
      removeProfessional: professional,
      title,
      subTitle,
      status,
    }));
  };

  onRemoveSubmit = (professional) => {
    this.props.onRemoveItem(professional);
    window.location.reload();
  };

  onApproveSubmit = (professional) => {
    this.props.onApproveItem(professional);

    window.location.reload();
  };
  onEditSubmit = (professional) => {
    this.props.onEditItem(professional);
    // window.location.reload()
  };

  getProfessionalsList = (professionalListData) => {
    if (professionalListData && professionalListData.length) {
      return professionalListData.map((user, i) => {
        const userApproved = userStatus(user.approved);
        const userVerified = userStatus(user.verified);
        const userActive = userStatus(user.active);
        let otherQualifications;

        if (
          !!user.metadata?.otherQualifications &&
          user.metadata?.otherQualifications.length > 0
        ) {
          otherQualifications = user.metadata?.otherQualifications[0];
        }

        if (
          !!user.metadata?.otherQualifications &&
          user.metadata?.otherQualifications.length > 0
        ) {
          otherQualifications = user.metadata?.otherQualifications[0];
        }

        return (
          <Tr key={user?._id}>
            {/*<Td><Image src={user?.photo} style={{borderRadius: 25}}/></Td>*/}
            <Td>
              {user?.name} {user?.surname}
              <br />
              <br />
              {user?.telephone}
            </Td>
            <Td>
              {user?.email}
              <br />
              <br />
              {user.metadata?.QRMetadata && (
                <QRCodeView qrcode={user.metadata.QRMetadata} i={i} />
              )}
            </Td>

            <Td
              style={{
                breakWord: "breakAll",
                maxWidth: 250,
                width: "100%",
              }}
            >
              {user.metadata?.bio?.length > 300
                ? `${user.metadata?.bio.slice(0, 300)}...`
                : user.metadata?.bio}
            </Td>
            <Td>{!user.active ? user?.step : 5} / 5</Td>
            <Td>
              {user?.createdAt ? (
                <div>Create:{dayjs(user.createdAt).format("DD.MM.YYYY")}</div>
              ) : (
                ""
              )}
              {user?.updatedAt ? (
                <div>Update:{dayjs(user.updatedAt).format("DD.MM.YYYY")}</div>
              ) : (
                ""
              )}
              {user?.approvedDate ? (
                <div>
                  Approved:{dayjs(user.approvedDate).format("DD.MM.YYYY")}
                </div>
              ) : (
                ""
              )}
            </Td>
            <Td>
              {userApproved}

              {/*<div>*/}
              {/*  <Button*/}
              {/*    className="btn waves-effect waves-light"*/}
              {/*    onClick={() => {*/}
              {/*      user.status = 'Pending'*/}
              {/*      this.onRemoveOpen(user,*/}
              {/*        'Do you want to \'Pending\' this professional?',*/}
              {/*        'Disable professional',*/}
              {/*        ProfessionalActionTypes.DISABLE);*/}
              {/*    }}*/}
              {/*  ><i className="bx bxs-down-arrow font-size-16 align-middle"></i></Button>*/}
              {/*</div>*/}
            </Td>
            <Td>{userVerified}</Td>
            <Td>{userActive}</Td>
            <Td>
              <button
                onClick={() => {
                  this.onRatingsOpen(user);
                }}
                className="btn-primary btn waves-effect waves-light"
              >
                <span style={{ fontSize: "12px" }}>
                  {Math.round(Number(user?.rating) / 0.5) * 0.5}
                </span>
                <i
                  className="bx bxs-star font-size-12 align-middle"
                  style={{
                    color: "yellow",
                    padding: "2px",
                    marginBottom: "2px",
                  }}
                ></i>
              </button>
            </Td>
            <Td>
              <div className="button-items d-flex">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    this.onEditOpen(user);
                  }}
                >
                  <i className="bx bxs-edit font-size-16 align-middle"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-warning waves-effect waves-light"
                  onClick={() => {
                    this.onServiceOpen(user);
                  }}
                >
                  <i className="bx bx-calendar font-size-16 align-middle"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-warning waves-effect waves-light"
                  onClick={() => {
                    this.onWorkScheduleOpen(user);
                  }}
                >
                  <i className="bx bx-time font-size-16 align-middle"></i>
                </button>
              </div>
            </Td>
          </Tr>
        );
      });
    } else {
      return null;
    }
  };

  render() {
    const {
      isEditModalOpen,
      isRemoveModalOpen,
      isServiceEditModalOpen,
      isViewRatingsOpen,
      isWorkScheduleModalOpen,
      isIdCardOpen,
      idCardProfessional,
      removeProfessional,
      editProfessional,
      defaultValues,
      title,
      subTitle,
      status,
    } = this.state;
    const { list } = this.props;
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                {/*<Th data-priority="1">Avatar </Th>*/}
                <Th data-priority="1">Name </Th>
                <Th data-priority="3">Email</Th>
                <Th data-priority="3">Bio</Th>
                <Th data-priority="3">Profile Step</Th>
                <Th data-priority="3">Info</Th>
                <Th data-priority="3">Approved</Th>
                <Th data-priority="3">Verified</Th>
                <Th data-priority="3">Active</Th>
                <Th data-priority="3">Ratings</Th>
                <Th data-priority="3">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>{this.getProfessionalsList(list?.users)}</Tbody>
          </Table>
        </div>
        <RemoveList
          data={removeProfessional}
          onSubmit={
            status === ProfessionalActionTypes.DISABLE
              ? this.onApproveSubmit
              : this.onRemoveSubmit
          }
          isRemoveModalOpen={isRemoveModalOpen}
          onCancel={this.onRemoveOpen}
          title={title}
          subTitle={subTitle}
          status={status}
        />
        {editProfessional && (
          <ProfessionalEdit
            admin={this.props?.user}
            data={editProfessional}
            onSubmit={this.onApproveSubmit}
            onUpdate={this.onEditSubmit}
            createProfessionalMetaHeadData={
              this.props?.createProfessionalMetaHeadData
            }
            isEditModalOpen={isEditModalOpen}
            onCancel={this.onEditOpen}
            removeOrder={this.onRemoveOpen}
          />
        )}
        {editProfessional && (
          <ServiceList
            user={editProfessional}
            defaultValues={defaultValues}
            setServiceList={this.setState.bind(this)}
            isServiceEditModalOpen={isServiceEditModalOpen}
            onCancel={this.onServiceOpen}
            onUpdate={this.onEditSubmit}
          />
        )}

        {isWorkScheduleModalOpen && (
          <WorkSchedule
            professional={editProfessional}
            isWorkScheduleModalOpen={isWorkScheduleModalOpen}
            onCancel={this.onWorkScheduleOpen}
          />
        )}

        {editProfessional && (
          <Ratings
            user={editProfessional}
            isOpen={isViewRatingsOpen}
            onCancel={this.onRatingsOpen}
            onUpdate={this.onRatingsOpen}
          />
        )}
      </div>
    );
  }
}

export default connect(
  ({ Login }) => {
    return Login;
  },
  { getTranslation, createProfessionalMetaHeadData },
)(ProfessionalList);
